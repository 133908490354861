import React from "react"

import Layout from "../../components/layout/layout"
import Impressum from "../../components/impressum/impressum-container"
import SEO from "../../components/seo/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Impressum" />
    <Impressum />
  </Layout>
)

export default IndexPage
