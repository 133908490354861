/* eslint-disable react/no-danger */

import PropTypes from "prop-types"
import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStamp } from "@fortawesome/free-solid-svg-icons"

import "./impressum.css"

const Impressum = ({ html }) => (
  <div className="impressum">
    <section>
      <header>
        <h1>Impressum</h1>
        <FontAwesomeIcon icon={faStamp} size="2x" />
      </header>

      <div dangerouslySetInnerHTML={{ __html: html }} />
    </section>
  </div>
)

Impressum.propTypes = {
  html: PropTypes.node.isRequired,
}

export default Impressum
